<template>
    <div class="warning-info-warp">
        <div class="warning-info-scroll" ref="warningInfoScroll">
            <div ref="warningInfoMove" v-if="list.length>0">
                <ul class="warning-info-list">
                    <li v-for="(item,index) in list" :key="index" :class="{alert:item.WarningType==2,warning:item.WarningType==3,alarm:item.WarningType==4}" @click="onGoWarningList">{{item.Content}}</li>
                </ul>
                <ul class="warning-info-list" v-if="repeatlist">
                    <li v-for="(item,index) in list" :key="index" :class="{alert:item.WarningType==2,warning:item.WarningType==3,alarm:item.WarningType==4}" @click="onGoWarningList">{{item.Content}}</li>
                </ul>
            </div>
            <div ref="warningInfoMove" v-else class="data-panel-headtext" style="text-align:center;line-height:100px;">
                暂无信息
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            list:[],
            repeatlist:false,
            intervalTime:null,
        }
    },
    props:{
        data:{
            type:Array
        },
        //滚动间隔时间
        scrollTime:{
            type:Number,
            default:30
        },
        //是否平滑滚动,首尾相接的滚动
        continuity:{
            type:Boolean,
            default:true
        },
        //最小滚动行数
        minScrollLine:{
            type:Number,
            default:0,
        },
    },
    methods:{
        onShowData(){
            var moveTarget = this.$refs.warningInfoMove;
            var warningInfoScroll = this.$refs.warningInfoScroll;
            //无数据显示
            if(this.list.length==0){
                this.$refs.warningInfoScroll.style='height:100px';
                return;
            }
            if(this.minScrollLine>0 && this.list.length<this.minScrollLine){
                this.repeatlist = false;
            }
            console.log(this.repeatlist);

            var bodyHeight = 25 * this.list.length;
            if(this.minScrollLine>0){
                bodyHeight = 25 * this.minScrollLine;
            }
            this.$refs.warningInfoScroll.style='height:'+bodyHeight+'px';

            //判断是否允许滚动
            var isscroll = false;
            var repeatlist = this.repeatlist;
            if(this.scrollTime>0){
                isscroll = true;
                if(this.minScrollLine>0 && this.list.length<this.minScrollLine){
                    isscroll = false;
                }
            }

            if(isscroll > 0){
                var initTop = 0;
                if(this.intervalTime){
                    clearInterval(this.intervalTime);
                }
                this.intervalTime = setInterval(function(){
                    initTop ++;
                    if(repeatlist){
                        if(initTop >= moveTarget.offsetHeight / 2 ){
                            initTop = 0;
                        }
                    }else{
                        if(initTop == moveTarget.offsetHeight){
                            initTop = -1*warningInfoScroll.clientHeight;
                        }
                    }
                    moveTarget.style = 'transform: translateY('+ (initTop*-1) +'px)';
                },this.scrollTime)
            }
        },
        onGoWarningList(){
            this.$router.push('/warning/index');
        },
    },
    mounted(){
        this.repeatlist = this.continuity;
        this.list = this.data;
        this.onShowData();
    },
    filters:{
        warningText(value){
            if(value==1){
                return "注意级";
            }else if(value==2){
                return "警示级";
            }else if(value==3){
                return "警戒级";
            }else if(value==4){
                return "警报级";
            }
        }
    },
    watch:{
        data:{
            handler:function(){
                this.list = this.data;
                this.onShowData();
            },
            deep:true
        },
        minScrollLine:function(){
            this.onShowData();
        }
    }
}
</script>