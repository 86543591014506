<template>
  <div style="padding: 10px">
    <el-row>
      <el-col :span="12">
        <div
          id="device-total-count"
          style="width: 100px; height: 100px; margin: 0 auto;cursor: pointer;"
          @click="onGoDeviceList"
        ></div>
      </el-col>
      <el-col :span="12">
        <div
          id="device-online-count"
          style="width: 100px; height: 100px; margin: 0 auto;cursor: pointer;"
          @click="onGoDeviceList"
        ></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");

require("echarts/lib/component/polar");
require("echarts/lib/component/title");

export default {
  props: {
    total: {
      type: Number,
      require: true,
    },
    online: {
      type: Number,
      require: true,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    refreshTime: {
      type: Number,
      default: 5000,
    },
  },
  methods: {
    initChart() {
      const option = {
        title: [
          {
            text: "设备总数",
            x: "center",
            top: "53%",
            textStyle: {
              color: "#20FEFD",
              fontSize: "11",
              fontWeight: "100",
            },
          },
          {
            text: "75",
            x: "center",
            top: "26%",
            textStyle: {
              fontSize: "26",
              color: "#20FEFD",
              fontFamily: "DINAlternate-Bold, DINAlternate",
              foontWeight: "600",
            },
          },
        ],
        polar: {
          radius: ["55%", "75%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            roundCap: true,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(155, 155, 155, .6)",
            },
            data: [100],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#6648FF",
                  },
                  {
                    offset: 1,
                    color: "#16CEB9",
                  },
                ]),
              },
            },
          },
        ],
      };
      // 绘制图表

      var totalOption = JSON.parse(JSON.stringify(option));
      totalOption.title[0].text = "设备总数";
      totalOption.title[1].text = this.total;
      totalOption.angleAxis.max = this.total;
      totalOption.series[0].data[0] = this.total;
      var chartTotal = echarts.init(
        document.getElementById("device-total-count")
      );
      chartTotal.setOption(totalOption);
      this.chartTotal = chartTotal;

      var onlineOption = JSON.parse(JSON.stringify(option));
      onlineOption.title[0].text = "在线总数";
      onlineOption.title[1].text = this.online;
      onlineOption.angleAxis.max = this.total;
      onlineOption.series[0].data[0] = this.total-this.online;
      var chartOnline = echarts.init(
        document.getElementById("device-online-count")
      );
      chartOnline.setOption(onlineOption);
      this.chartOnline = chartOnline;

      if (this.refresh) {
        setInterval(() => {
          chartTotal.clear();
          chartTotal.setOption(totalOption);

          chartOnline.clear();
          chartOnline.setOption(onlineOption);
        }, this.refreshTime);
      }
    },
    onGoDeviceList(){
        this.$router.push('/device/index');
    },
  },
  mounted() {
    this.initChart();
  },
  watch:{
    total:function(){
      this.initChart();
    },
    online:function(){
      this.initChart();
    }
  }
};
</script>