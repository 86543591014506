<template>
  <div class="main-index-container">
    <div id="geologyMainMap" class="map-tianditu"></div>

    <div class="map-left-view" style="width: 280px">
      <div>
        <transition name="map-dataleft">
          <div style="margin-bottom: 20px; padding-left: 20px" v-if="mapDataLeftShow">
            <data-panel title="监测点列表">
              <el-carousel trigger="click" :height="leftHeight" :autoplay="false" class="geology-carousel">
                <el-carousel-item v-for="(
                                        elem, i
                                    ) in monitorDeviceListTabdata" :key="i">
                  <div class="main-danger-info-list">
                    <div v-for="(item, index) in elem" :key="'elem' + index" class="main-danger-info-dangeritem" :class="{
                      active:
                        dangerActiveIndex ==
                        i *
                        monitorDeviceListOffset +
                        index,
                    }" @click="
  changeDanger(
    i *
    monitorDeviceListOffset +
    index,
    item
  )
">
                      <div class="title">
                        {{ item.hiddanger.DangerName }}
                      </div>
                      <div class="info" style="margin-bottom: 5px">
                        类型：{{
                            item.hiddanger.DangerType
                        }}
                      </div>
                      <div class="info" style="margin-bottom: 5px">
                        等级：{{
                            item.hiddanger.DangerLevel
                        }}
                      </div>
                      <div class="info danger-marks">
                        备注：{{ item.hiddanger.Marks }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </data-panel>
          </div>
        </transition>
      </div>

      <div class="panel-foldopen-left" @click="mapDataLeftShow = !mapDataLeftShow">
        <template v-if="!mapDataLeftShow">
          <i class="icon el-icon-caret-right"></i>
          <i class="icon el-icon-caret-right"></i>
        </template>
        <template v-else>
          <i class="icon el-icon-caret-left"></i>
          <i class="icon el-icon-caret-left"></i>
        </template>
      </div>
    </div>

    <div class="map-right-view" style="width: 280px">
      <div style="height: 660px" v-if="monitorDeviceListOffset == 5">
        <transition name="map-dataright">
          <div style="margin-bottom: 20px; padding-right: 20px" v-if="mapDataRightShow">
            <data-panel title="实时预警" body-class="warning-early-panel">
              <el-row>
                <!-- <el-col :span="6" style="color: #32c5ff">
                                    <div class="label">注意级</div>
                                    <div class="value">
                                        {{ waringDataCount.warningZyData }}
                                    </div>
                                </el-col> -->
                <el-col :span="8" style="color: #fbca29">
                  <div class="label">警示级</div>
                  <div class="value">
                    {{ waringDataCount.warningJsData }}
                  </div>
                </el-col>
                <el-col :span="8" style="color: #ff9e17">
                  <div class="label">警戒级</div>
                  <div class="value">
                    {{ waringDataCount.warningJjData }}
                  </div>
                </el-col>
                <el-col :span="8" style="color: #ff3b20">
                  <div class="label">警报级</div>
                  <div class="value">
                    {{ waringDataCount.warningJbData }}
                  </div>
                </el-col>
              </el-row>
            </data-panel>
            <data-panel title="历史预警信息">
              <panel-warning-info :scroll-time="50" :min-scroll-line="5" :continuity="true" :data="waringDataList">
              </panel-warning-info>
            </data-panel>
            <data-panel title="设备在线统计">
              <panel-device-state :total="deviceTotalCount" :online="deviceOnlineCount" :refresh="false"
                :refresh-time="10000"></panel-device-state>
            </data-panel>
            <data-panel title="设备占比">
              <panel-device-radio id="deviceDataRadio" style="height: 180px" :data="deviceRadioData">
              </panel-device-radio>
            </data-panel>
          </div>
        </transition>
      </div>
      <div style="height: 480px" v-if="monitorDeviceListOffset == 4">
        <transition name="map-dataright">
          <div style="margin-bottom: 20px; padding-right: 20px" v-if="mapDataRightShow">
            <el-carousel trigger="click" height="480px" :autoplay="false" class="geology-carousel">
              <el-carousel-item>
                <data-panel title="实时预警" body-class="warning-early-panel">
                  <el-row>
                    <!-- <el-col
                                            :span="6"
                                            style="color: #32c5ff"
                                        >
                                            <div class="label">注意级</div>
                                            <div class="value">
                                                {{
                                                    waringDataCount.warningZyData
                                                }}
                                            </div>
                                        </el-col> -->
                    <el-col :span="8" style="color: #fbca29">
                      <div class="label">警示级</div>
                      <div class="value">
                        {{
                            waringDataCount.warningJsData
                        }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="color: #ff9e17">
                      <div class="label">警戒级</div>
                      <div class="value">
                        {{
                            waringDataCount.warningJjData
                        }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="color: #ff3b20">
                      <div class="label">警报级</div>
                      <div class="value">
                        {{
                            waringDataCount.warningJbData
                        }}
                      </div>
                    </el-col>
                  </el-row>
                </data-panel>
                <data-panel title="历史预警信息">
                  <panel-warning-info :scroll-time="50" :min-scroll-line="5" :continuity="true" :data="waringDataList">
                  </panel-warning-info>
                </data-panel>
                <data-panel title="设备在线统计">
                  <panel-device-state :total="deviceTotalCount" :online="deviceOnlineCount" :refresh="false"
                    :refresh-time="10000"></panel-device-state>
                </data-panel>
              </el-carousel-item>
              <el-carousel-item>
                <data-panel title="设备占比">
                  <panel-device-radio id="deviceDataRadio" style="height: 180px" :data="deviceRadioData">
                  </panel-device-radio>
                </data-panel>
              </el-carousel-item>
            </el-carousel>
          </div>
        </transition>
      </div>

      <div style="text-align: right">
        <div class="panel-foldopen-right" @click="mapDataRightShow = !mapDataRightShow">
          <template v-if="mapDataRightShow">
            <i class="icon el-icon-caret-right"></i>
            <i class="icon el-icon-caret-right"></i>
          </template>
          <template v-else>
            <i class="icon el-icon-caret-left"></i>
            <i class="icon el-icon-caret-left"></i>
          </template>
        </div>
      </div>
    </div>

    <el-dialog title="监测点详情" :visible.sync="dialogDangerDetailVisible" width="60%" custom-class="geology-dialog"
      :modal-append-to-body="true">
      <danger-detail :detailid="dialogDangerId" :visible="dialogDangerDetailVisible"></danger-detail>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" style="margin-left: 10px" plain type="primary"
          @click="dialogDangerDetailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss">
.main-index-container {
  position: relative;
  height: 100%;
}

#geologyMainMap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.map-bottom-view-scrollbar {
  flex: 1;

  .map-bottom-view-scrollbar-wrapper {
    overflow-x: hidden;
  }
}
</style>
<script>
import Vue from "vue";
import Tianditu from "@/common/tianditu";
import dataPanel from "./components/data-panel";
import panelWarningInfo from "./components/panel-warning-info";
import panelDeviceState from "./components/panel-device-state";
import panelDeviceRadio from "./components/panel-device-radio";

import dangerDetail from "../danger/components/dangerDetail";
import {
  getMonitorDeviceList,
  getWaringMsgAndCount,
  getHomeDeviceCount,
  getHomeDeviceRadio,
} from "@/api/user";
import IconMapDw1 from "@/assets/map-dw1.png"

Vue.use(Tianditu);
export default {
  data() {
    return {
      tMap: "",
      markers: [],

      mapDataLeftShow: true,
      mapDataRightShow: true,
      mapDataBottomShow: true,

      waringDataCount: "",
      waringDataList: [],

      deviceTotalCount: 0,
      deviceOnlineCount: 0,

      deviceRadioData: "",

      monitorDeviceListOffset: 4, //左侧监测点列表个数
      monitorDeviceListTabdata: [],
      monitorDeviceList: [],
      activeIndex: 0,

      dangerActiveIndex: -1,

      dialogDangerDetailVisible: false,
      dialogDangerId: 0,
    };
  },
  components: {
    dataPanel,
    panelWarningInfo,
    panelDeviceState,
    panelDeviceRadio,
    dangerDetail,
  },
  computed: {
    leftHeight() {
      if (this.monitorDeviceList.length < this.monitorDeviceListOffset) {
        return this.monitorDeviceList.length * 120 + 40 + "px";
      }
      if (this.monitorDeviceListOffset == 4) {
        return "480px";
      } else {
        return "600px";
      }
    },
  },
  mounted() {
    var that = this;
    this.$tianditu.init({
      key: "328016e1243b27658e2b50b7cadda87b",
      el: "geologyMainMap",
      config: {
        minZoom: 4,
        maxZoom: 16,
        datasourcesControl: false,
      },
      success(map) {
        that.tMap = map;
        map.setMapType(window.TMAP_HYBRID_MAP);
        getMonitorDeviceList().then((res) => {
          if (res.code == 0) {
            var offset = 4;
            if (document.body.offsetHeight > 700) {
              offset = 5;
            }
            var resdata = [];
            for (
              let index = 0;
              index < res.data.length;
              index = index + offset
            ) {
              var listdata = [];
              for (let i = 0; i < offset; i++) {
                if (index + i < res.data.length) {
                  listdata.push(res.data[index + i]);
                }
              }
              resdata.push(listdata);
            }
            that.monitorDeviceListOffset = offset;
            that.monitorDeviceListTabdata = resdata;
            that.monitorDeviceList = res.data;
            that.changeDanger(-1);
          }
        });
      },
    });
  },
  methods: {
    getMapInfoHtml(monitorinfo) {
      var html =
        '<div class="map-dialog-container"><div class="map-dialog-title">监测点位</div><div class="map-dialog-localname">' +
        monitorinfo.MonitorName +
        '</div><div class="map-dialog-localitem"><label>所属隐患点</label><div class="map-dialog-localvalue">' +
        monitorinfo.DangerName +
        '</div></div><div class="map-dialog-localitem"><label for="">联系人</label><div class="map-dialog-localvalue">' +
        monitorinfo.Uname +
        '</div></div><div class="map-dialog-localitem"><label for="">联系电话</label><div class="map-dialog-localvalue">' +
        monitorinfo.Mobile +
        '</div></div><div class="map-dialog-localitem"><label for="">等级</label><div class="map-dialog-localvalue">' +
        monitorinfo.DangerLevel +
        '</div></div><div  class="map-dialog-actions"><a href="/device/detail?id=' +
        monitorinfo.DeviceId +
        '" class="map-dialog-btn">数据查看 >></a></div></div>';
      return html;
    },
    //地图显示设备信息
    showMaplocation() {
      var nowdata = [];
      if (this.dangerActiveIndex == -1) {
        //显示所有
        this.monitorDeviceList.forEach((element) => {
          nowdata = nowdata.concat(element.monitorlist);
        });
      } else {
        nowdata = this.monitorDeviceList[this.dangerActiveIndex]
          .monitorlist;
      }

      if (this.tMap) {
        var map = this.tMap;
        var that = this;
        map.clearOverLays();
        if (nowdata.length > 0) {
          map.centerAndZoom(
            new T.LngLat(nowdata[0].Longitude, nowdata[0].Latitude),
            16
          );
        }
        var markers = [];
        for (let index = 0; index < nowdata.length; index++) {
          const element = nowdata[index];
          var icon1 = new T.Icon({
            iconUrl: element.DImage,
            iconSize: new T.Point(22, 22),
            iconAnchor: new T.Point(11, 11),
          });

          (function (nowlocal) {
            var marker = new T.Marker(
              new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
              { icon: icon1 }
            );
            marker.addEventListener(
              "click",
              function () {
                var html = that.getMapInfoHtml(nowlocal);
                var infoWin = new T.InfoWindow(html);
                marker.openInfoWindow(infoWin);
                map.centerAndZoom(
                  new T.LngLat(
                    nowlocal.Longitude,
                    nowlocal.Latitude
                  ),
                  map.getZoom()
                );
              },
              this
            );
            markers.push(marker);
            map.addOverLay(marker);
          })(element);
          this.markers = markers;
        }
      }
    },
    //地图上显示灾害点信息
    showMapHiddanger() {
      var nowdata = [];
      this.monitorDeviceList.forEach((element) => {
        nowdata = nowdata.concat(element.hiddanger);
      });

      if (this.tMap) {
        var map = this.tMap;
        var that = this;
        map.clearOverLays();
        if (nowdata.length > 0) {
          map.centerAndZoom(
            new T.LngLat(nowdata[0].Longitude, nowdata[0].Latitude),
            16
          );
        }
        var markers = [];
        for (let index = 0; index < nowdata.length; index++) {
          const element = nowdata[index];
          var icon1 = new T.Icon({
            iconUrl: IconMapDw1,
            iconSize: new T.Point(32, 32),
            iconAnchor: new T.Point(16, 16),
          });

          (function (nowlocal) {
            var marker = new T.Marker(
              new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
              { icon: icon1 }
            );
            marker.addEventListener(
              "click",
              function () {
                console.log(nowlocal);
                that.dialogDangerId = nowlocal.Id;
                that.dialogDangerDetailVisible = true;
              },
              this
            );
            markers.push(marker);
            map.addOverLay(marker);
          })(element);
          this.markers = markers;
        }
      }
    },
    changeDanger(index) {
      if (index == -1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
      this.dangerActiveIndex = this.activeIndex;
      //this.showMaplocation();
      //2022.05.11修改为地图中显示灾害点
      this.showMapHiddanger();
      if (index > -1) {
        var item = this.monitorDeviceList[this.activeIndex];
        this.dialogDangerId = item.hiddanger.Id;
        this.dialogDangerDetailVisible = true;
      }
    },
  },
  created() {
    getWaringMsgAndCount().then((res) => {
      if (res.code == 0) {
        var waringDataCount = res.data.waring;
        var warningZyData = 0,
          warningJsData = 0,
          warningJjData = 0,
          warningJbData = 0;
        waringDataCount.forEach((element) => {
          if (element.type == "1") {
            warningZyData = element.count;
          } else if (element.type == "2") {
            warningJsData = element.count;
          } else if (element.type == "3") {
            warningJjData = element.count;
          } else if (element.type == "4") {
            warningJbData = element.count;
          }
        });
        this.waringDataCount = {
          warningZyData: warningZyData,
          warningJsData: warningJsData,
          warningJjData: warningJjData,
          warningJbData: warningJbData,
        };
        this.waringDataList = res.data.list;
      }
    });
    getHomeDeviceCount().then((res) => {
      if (res.code == 0) {
        this.deviceTotalCount = res.data.fcount + res.data.ocount;
        this.deviceOnlineCount = res.data.ocount;
      }
    });
    getHomeDeviceRadio().then((res) => {
      if (res.code == 0) {
        this.deviceRadioData = res.data;
      }
    });
  },
  beforeDestroy() {
    this.$tianditu.destroy();
  },
};
</script>