<template>
    <div class="data-view-panel">
        <div class="data-panel-head">
            <div class="data-panel-headtext">{{ title }}</div>
        </div>
        <div class="data-panel-body" :class="bodyClass">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        bodyClass: String,
    },
};
</script>